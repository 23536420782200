import React, {Component} from 'react';
import InputText from "../FormComponents/InputText";

class AddBoard extends Component{
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            nameError: ''
        };

        this.setBoardName = this.setBoardName.bind(this);
        this.createBoard = this.createBoard.bind(this);
    }

    setBoardName(name, value){
        this.setState({
            name: value,
            nameError: ''
        });
    }

    createBoard(e){
        e.preventDefault();

        if(this.state.name.length > 0) {
            this.props.createBoard(this.state.name);
            this.setState({
                name: ''
            });
        }else{
            this.setState({
                nameError: 'El nombre no puede estar vacio'
            });
        }
    }

    render(){
        return (
            <form onSubmit={this.createBoard}>
                <InputText type="text"
                           value={this.state.name}
                           placeholder="Lista de..."
                           label="Nuevo tablero"
                           name="board_name"
                           error={this.state.nameError}
                           onChange={this.setBoardName}/>
            </form>
        )
    }
}

export default AddBoard;