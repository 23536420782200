const actionTypes = {
    ADD_TASK: 'boards_add_task',
    UPDATE_TASK: 'boards_update_task',
    REMOVE_TASK: 'boards_remove_task',
    LOADING_BOARDS: 'boards_loading_boards',
    SET_BOARD_LIST: 'boards_set_board_list',
    SET_BOARD_TASKS: 'boards_set_board_tasks',
    SET_SELECTED_BOARD: 'boards_set_selected_board',
    UPDATE_BOARD: 'boards_update_board',
    REMOVE_BOARD: 'boards_remove_board',
    LOADING_BOARD: 'boards_loading_board',
    ADD_BOARD: 'boards_add_board',
    LOADING_CREATE_BOARD: 'boards_loading_create_board',
    LOADING_UPDATE_BOARD: 'boards_loading_update_board'
};

export default actionTypes;