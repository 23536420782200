import React, {Component} from 'react';
import RemoveTask from "./RemoveTask";
import TaskStatus from "./TaskStatus";

class Task extends Component{
    constructor(props) {
        super(props);
        this.prepareToUpdate = this.prepareToUpdate.bind(this);
        this.setUpdateParam = this.setUpdateParam.bind(this);
        this.updateTask = this.updateTask.bind(this);

        this.inputRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.tempTask.id === this.props.task.id && prevProps.tempTask.id !== prevProps.task.id){
            this.inputRef.current.focus();
        }
    }

    prepareToUpdate(){
        const task = this.props.task;
        this.props.prepareToUpdate(task.id, task.boardId, task.title, task.status);
    }

    setUpdateParam(e){
        this.props.setUpdateParam('title', e.target.value);
    }

    updateTask(e){
        e.preventDefault();
        this.props.updateTask();
    }

    render(){
        const isUpdate = this.props.tempTask.id === this.props.task.id;

        return (
            <div className="task">
                <TaskStatus task={this.props.task} updateStatus={this.props.updateStatus}/>
                {!isUpdate && <div className={"name" + (this.props.task.status? ' done' : '')} onClick={this.prepareToUpdate}>{this.props.task.title}</div>}
                {isUpdate &&  <div className="update-task">
                    <form onSubmit={this.updateTask}>
                        <input type="text"
                               value={this.props.tempTask.title}
                               placeholder="Chips"
                               name="title"
                               onChange={this.setUpdateParam}
                               ref={this.inputRef}/>
                    </form>
                </div>}
                <RemoveTask taskId={this.props.task.id} boardId={this.props.task.boardId} removeTask={this.props.removeTask}/>
            </div>
        )
    }
}

export default Task;