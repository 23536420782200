import React, {Component} from 'react';

class TaskStatus extends Component{
    constructor(props) {
        super(props);
        this.changeStatus = this.changeStatus.bind(this);
    }

    changeStatus(e){
        const task = this.props.task;
        this.props.updateStatus(task.id, task.boardId, task.title, e.target.checked);
    }

    render(){
        return (
            <div className="task-status">
                <input type="checkbox" name="status" checked={this.props.task.status} onChange={this.changeStatus}/>
            </div>
        );
    }
}

export default TaskStatus;