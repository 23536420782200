import React, {Component} from 'react';
import {Redirect, Route} from "react-router";
import {connect} from "react-redux";

class PrivateRoute extends Component{
    render(){
        const { component: Component, token, ...rest} = this.props;

        return <Route {...rest} render={props => {
            if(token && token.length > 0) {
                return <Component {...props}/>;
            }else{
                return <Redirect to={{pathname: '/'}}/>
            }
        }}/>
    }
}

const mapStateToProps = state => ({
    token: state.auth.token
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);