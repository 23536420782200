import React, {Component} from 'react';
import {Route, Switch} from "react-router";
import Home from "./Pages/Home";
import Register from "./Pages/Register";
import PrivateRoute from "./Components/Common/PrivateRoute";
import {connect} from "react-redux";
import BoardList from "./Pages/BoardList";
import Board from "./Pages/Board";
import NotFound from "./Pages/NotFound";
import authActions from './ActionCreators/Auth';
import Exit from "./Pages/Exit";

class App extends Component{
    componentWillMount() {
        this.props.readCookie();
    }

    render(){
        return (
            <div className="container">
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/register" component={Register}/>
                    <PrivateRoute exact path="/boards" component={BoardList}/>
                    <PrivateRoute exact path="/board/:id" component={Board}/>
                    <Route exact component={Exit}/>
                    <Route component={NotFound}/>
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = null;

const mapDispatchToProps = ({
    readCookie: authActions.readCookie
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
