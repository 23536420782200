import React from 'react';
import authActions from "../ActionCreators/Auth";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class Exit extends React.Component{
    componentWillMount() {
        this.props.exit();
        this.props.history.push('/');
    }

    render(){
        return null;
    }
}

const mapStateToProps = null;

const mapDispatchToProps = ({
    exit: authActions.exitApp
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exit));
