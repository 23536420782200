import React, {Component} from 'react';
import InputText from "../FormComponents/InputText";

class AddTask extends Component{
    constructor(props) {
        super(props);
        this.state = {
            titleError: ''
        };

        this.setCreateParam = this.setCreateParam.bind(this);
        this.createTask = this.createTask.bind(this);
    }

    setCreateParam(name, value){
        this.setState({
            titleError: false
        });
        this.props.setCreateParam(name, value);
    }

    createTask(e){
        e.preventDefault();

        if(this.props.task.title.length > 0){
            this.props.createTask();
        }else{
            this.setState({
                titleError: true
            });
        }
    }

    render(){
        return (
            <div className="new-task">
                <form onSubmit={this.createTask}>
                    <InputText type="text"
                               value={this.props.task.title}
                               placeholder="Chips"
                               label="Nueva tarea"
                               name="title"
                               error={this.state.titleError}
                               onChange={this.setCreateParam}/>
                </form>
            </div>
        );
    }
}

export default AddTask;