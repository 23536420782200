import cookie from "react-cookies";

export const checkStatus = (response) => {
    if(response.status < 300){
        return response;
    }else{
        if(response.status === 401) {
            cookie.remove('tasks', {path: '/'});
            window.location.reload();
        }else{
            let error = new Error(response.status);
            error.response = response;
            throw error;
        }
    }
};

export const checkStatusAuth = (response) => {
    if(response.status < 300){
        return response;
    }else{
        let error = new Error(response.status);
        error.response = response;
        throw error;
    }
};

export const parseJSON = (response) => {
    return response.json();
};