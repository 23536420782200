import {checkStatus, parseJSON} from "./Utils";

const ApiBoards = {
    getBoardList: (token) => {
        return fetch('/api/boards', {
            method: 'get',
            headers: {
                token
            }
        }).then(checkStatus)
            .then(parseJSON);
    },
    getBoard: (token, boardId) => {
        return fetch('/api/board/' + boardId, {
            method: 'get',
            headers: {
                token
            }
        }).then(checkStatus)
            .then(parseJSON);
    },
    createBoard: (token, name) => {
        return fetch('/api/board', {
            method: 'post',
            headers: {
                token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name
            })
        }).then(checkStatus)
            .then(parseJSON);
    },
    updateBoard: (token, boardId, name) => {
        return fetch('/api/board/' + boardId, {
            method: 'put',
            headers: {
                token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name
            })
        }).then(checkStatus)
            .then(parseJSON);
    },
    removeBoard: (token, boardId) => {
        return fetch('/api/board/' + boardId, {
            method: 'delete',
            headers: {
                token,
                'Content-Type': 'application/json'
            }
        }).then(checkStatus)
            .then(parseJSON);
    }
};

export default ApiBoards;