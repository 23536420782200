import React, {Component} from 'react';
import {Link} from "react-router-dom";

class Menu extends Component{
    render(){
        return (
            <div className="menu">
                <div className="logo">
                    Tasks
                </div>
                <nav>
                    <ul>
                        <li><Link to="/boards">Tableros</Link></li>
                        <li><Link to="/exit">Salir</Link></li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Menu;
