import React, {Component} from 'react';

class InputText extends Component{
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e){
        this.props.onChange(this.props.name, e.target.value);
    }

    render(){
        const { name, label, type, value, placeholder, error} = this.props;

        return (
            <div className={"input-group" + (error.length > 0? ' error' : '')}>
                <label htmlFor={name}>{label}</label>
                <input type={type} name={name} id={name} value={value} placeholder={placeholder} onChange={this.onChange}/>
                {error.length > 0 && <div className="feedback-error">{error}</div>}
            </div>
        );
    }
}

export default InputText;