import React, {Component} from 'react';
import InputText from "../Components/FormComponents/InputText";
import {connect} from "react-redux";
import authActions from '../ActionCreators/Auth';
import {Link} from "react-router-dom";

class Register extends Component{
    constructor(props) {
        super(props);

        this.state = {
            usernameError: '',
            emailError: '',
            passwordError: ''
        };

        this.register = this.register.bind(this);
    }

    register(e){
        e.preventDefault();

        this.props.register();
    }

    render() {
        return (
            <div className="public-wrapper">
                <h1>Registro</h1>
                <div className="register-form">
                    <form onSubmit={this.register}>
                        <InputText type="text"
                                   value={this.props.registerForm.username}
                                   placeholder="BusinessMan"
                                   label="Nombre de usuario"
                                   name="username"
                                   error={this.state.usernameError}
                                   onChange={this.props.setRegisterParam}/>

                        <InputText type="email"
                                   value={this.props.registerForm.email}
                                   placeholder="example@example.com"
                                   label="Email"
                                   name="email"
                                   error={this.state.usernameError}
                                   onChange={this.props.setRegisterParam}/>

                        <InputText type="password"
                                   value={this.props.registerForm.password}
                                   placeholder="*****"
                                   label="Contraseña"
                                   name="password"
                                   error={this.state.passwordError}
                                   onChange={this.props.setRegisterParam}/>

                        <div className="actions">
                            <button type="submit">Crear cuenta</button>
                        </div>
                    </form>

                    <Link to="/">Iniciar sesión</Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    registerForm: state.auth.registerForm
});

const mapDispatchToProps = ({
    setRegisterParam: authActions.setRegisterParam,
    register: authActions.register
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);