import actionTypes from '../ActionTypes/Boards';
import update from 'immutability-helper';

const initialState = {
    loadingBoards: false,
    loadingBoard: false,
    loadingCreateBoard: false,
    loadingUpdateBoard: false,
    boards: [],
    tasks: [],
    board: {}
};

const boards = (state = initialState, action) => {
    let preSorted;

    switch(action.type){
        case actionTypes.LOADING_BOARDS:
            return update(state, {loadingBoards: {$set: action.payload.status}});
        case actionTypes.LOADING_BOARD:
            return update(state, {loadingBoard: {$set: action.payload.status}});
        case actionTypes.LOADING_CREATE_BOARD:
            return update(state, {loadingCreateBoard: {$set: action.payload.status}});
        case actionTypes.LOADING_UPDATE_BOARD:
            return update(state, {loadingUpdateBoard: {$set: action.payload.status}});
        case actionTypes.SET_BOARD_LIST:
            return update(state, {boards: {$set: action.payload.boards}});
        case actionTypes.SET_BOARD_TASKS:
            let sortedTasks = action.payload.tasks.sort((a, b) => a.title.localeCompare(b.title));
            return update(state, {tasks: {$set: sortedTasks}});
        case actionTypes.SET_SELECTED_BOARD:
            let board = state.boards.filter(b => b.id === action.payload.boardId);
            return update(state, {board: {$set: board[0]}});
        case actionTypes.REMOVE_BOARD:
            let boards = state.boards.filter(b => b.id !== action.payload.boardId);
            return update(state, {boards: {$set: boards}});
        case actionTypes.ADD_BOARD:
            return update(state, {boards: {$push: [{
                id: action.payload.boardId,
                name: action.payload.name
            }]}});
        case actionTypes.UPDATE_BOARD:
            let boardIndex = state.boards.findIndex(b => b.id === action.payload.boardId);
            return update(state, {boards: {[boardIndex]: {name: {$set: action.payload.name}}}});
        case actionTypes.REMOVE_TASK:
            let tasks = state.tasks.filter(t => t.id !== action.payload.taskId);
            return update(state, {tasks: {$set: tasks}});
        case actionTypes.ADD_TASK:
            preSorted = update(state, {tasks: {$push: [{
                id: action.payload.taskId,
                boardId: action.payload.boardId,
                title: action.payload.title,
                status: false
            }]}});
            return update(preSorted, {tasks: {$set: preSorted.tasks.sort((a, b) => a.title.localeCompare(b.title))}});
        case actionTypes.UPDATE_TASK:
            let taskIndex = state.tasks.findIndex(t => t.id === action.payload.taskId);
            preSorted = update(state, {tasks: {[taskIndex]: {title: {$set: action.payload.title}, status: {$set: action.payload.status}}}});
            return update(preSorted, {tasks: {$set: preSorted.tasks.sort((a, b) => a.title.localeCompare(b.title))}});
        default:
            return state;
    }
};

export default boards;