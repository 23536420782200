import actionTypes from '../ActionTypes/Tasks';
import update from 'immutability-helper';

const initialState = {
    loadingCreateTask: false,
    loadingUpdateTask: false,
    create: {
        boardId: 0,
        title: ''
    },
    update: {
        id: 0,
        boardId: 0,
        title: '',
        status: false
    }
};

const tasks = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.LOADING_CREATE_TASK:
            return update(state, {loadingCreateTask: {$set: action.payload.status}});
        case actionTypes.LOADING_UPDATE_TASK:
            return update(state, {loadingUpdateTask: {$set: action.payload.status}});
        case actionTypes.CLEAN_CREATE_TASK:
            return update(state, {create: {$set: initialState.create}});
        case actionTypes.CLEAN_UPDATE_TASK:
            return update(state, {update: {$set: initialState.update}});
        case actionTypes.SET_CREATE_PARAM:
            return update(state, {create: {[action.payload.name]: {$set: action.payload.value}}});
        case actionTypes.SET_UPDATE_PARAM:
            return update(state, {update: {[action.payload.name]: {$set: action.payload.value}}});
        case actionTypes.PREPARE_UPDATE_TASK:
            return update(state, {update: {$set: {
                id: action.payload.taskId,
                boardId: action.payload.boardId,
                title: action.payload.title,
                status: action.payload.status
            }}});
        default:
            return state;
    }
};

export default tasks;