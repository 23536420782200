const actionTypes = ({
    LOADING_CREATE_TASK: 'tasks_loading_create_task',
    CLEAN_CREATE_TASK: 'tasks_clean_create_task',
    SET_CREATE_PARAM: 'tasks_set_create_param',
    SET_UPDATE_PARAM: 'tasks_set_update_param',
    PREPARE_UPDATE_TASK: 'tasks_prepare_update_task',
    LOADING_UPDATE_TASK: 'tasks_loading_update_task',
    CLEAN_UPDATE_TASK: 'tasks_clean_update_task'
});

export default actionTypes;