import React, {Component} from 'react';

class NotFound extends Component{
    render(){
        return (
            <div>
                Página no encontrada
            </div>
        );
    }
}

export default NotFound;