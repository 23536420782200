import React, {Component} from 'react';
import InputText from "../Components/FormComponents/InputText";
import {connect} from "react-redux";
import authActions from '../ActionCreators/Auth';
import {Link} from "react-router-dom";
import {withRouter} from "react-router";

class Home extends Component{
    constructor(props) {
        super(props);

        this.state = {
            usernameError: '',
            passwordError: ''
        };

        this.login = this.login.bind(this);
    }

    login(e){
        e.preventDefault();

        this.props.login();
    }

    componentDidMount() {
        if(this.props.token.length > 0){
            this.props.history.push('/boards');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.token.length > 0){
            this.props.history.push('/boards');
        }
    }

    render() {
        return (
            <div className="public-wrapper">
                <h1>Inicio de sesión</h1>
                <div className="login-form">
                    <form onSubmit={this.login}>
                        <InputText type="text"
                                   value={this.props.loginForm.username}
                                   placeholder="BusinessMan"
                                   label="Nombre de usuario"
                                   name="username"
                                   error={this.state.usernameError}
                                   onChange={this.props.setLoginParam}/>

                        <InputText type="password"
                                   value={this.props.loginForm.password}
                                   placeholder="*****"
                                   label="Contraseña"
                                   name="password"
                                   error={this.state.passwordError}
                                   onChange={this.props.setLoginParam}/>

                        <div className="actions">
                            <button type="submit">Iniciar sesión</button>
                        </div>
                    </form>

                    <Link to="/register">Crear una cuenta</Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    loginForm: state.auth.loginForm
});

const mapDispatchToProps = ({
    setLoginParam: authActions.setLoginParam,
    login: authActions.login
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));