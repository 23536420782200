import React, {Component} from 'react';
import boardsActions from "../ActionCreators/Boards";
import tasksActions from "../ActionCreators/Tasks";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import Task from "../Components/Board/Task";
import AddTask from "../Components/Board/AddTask";
import Menu from "../Components/Menu/Menu";

class Board extends Component{
    componentWillMount() {
        const id = parseInt(this.props.match.params.id, 10);
        if(id > 0){
            this.props.getBoardList();
            this.props.getBoard(id);
            this.props.setCreateParam('boardId', id);
        }else{
            this.props.history.push('/boards');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const id = parseInt(this.props.match.params.id, 10);
        if(id > 0){
            const oldId = parseInt(prevProps.match.params.id, 10);
            if(id !== oldId) {
                this.props.getBoardList();
                this.props.getBoard(id);
                this.props.setCreateParam('boardId', id);
            }
        }else{
            this.props.history.push('/boards');
        }
    }

    render(){
        let pending = this.props.tasks.filter(t => !t.status);
        let done = this.props.tasks.filter(t => t.status);

        return (
            <div>
                <Menu/>

                <AddTask task={this.props.tempCreateTask}
                         createTask={this.props.createTask}
                         setCreateParam={this.props.setCreateParam}/>

                <div className="tasks">
                    {pending.map(t => <Task key={t.id}
                                            task={t}
                                            updateTask={this.props.updateTask}
                                            tempTask={this.props.tempUpdateTask}
                                            setUpdateParam={this.props.setUpdateParam}
                                            updateStatus={this.props.updateStatus}
                                            prepareToUpdate={this.props.prepareToUpdate}
                                            removeTask={this.props.removeTask}/>)}
                    {done.map(t => <Task key={t.id}
                                         task={t}
                                         updateTask={this.props.updateTask}
                                         tempTask={this.props.tempUpdateTask}
                                         setUpdateParam={this.props.setUpdateParam}
                                         updateStatus={this.props.updateStatus}
                                         prepareToUpdate={this.props.prepareToUpdate}
                                         removeTask={this.props.removeTask}/>)}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    tasks: state.boards.tasks,
    tempCreateTask: state.tasks.create,
    tempUpdateTask: state.tasks.update
});

const mapDispatchToProps = ({
    getBoardList: boardsActions.getBoardList,
    getBoard: boardsActions.getBoard,
    updateTask: tasksActions.updateTask,
    updateStatus: tasksActions.updateStatusTask,
    prepareToUpdate: tasksActions.prepareToUpdate,
    removeTask: tasksActions.removeTask,
    setUpdateParam: tasksActions.setUpdateParam,
    setCreateParam: tasksActions.setCreateParam,
    createTask: tasksActions.createTask
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Board));