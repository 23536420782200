import {checkStatusAuth, parseJSON} from "./Utils";

const ApiAuth = {
    login: (username, password) => {
        return fetch('/api/login', {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username,
                password
            })
        }).then(checkStatusAuth)
            .then(parseJSON);
    },

    register: (username, email, password) => {
        return fetch('/api/register', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username,
                email,
                password
            })
        }).then(checkStatusAuth)
            .then(parseJSON);
    }
};

export default ApiAuth;