import React, {Component} from 'react';
import Menu from "../Components/Menu/Menu";
import boardsActions from "../ActionCreators/Boards";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import BoardItem from "../Components/BoardList/BoardItem";
import AddBoard from "../Components/BoardList/AddBoard";

class BoardList extends Component{
    componentWillMount() {
        this.props.getBoardList();
    }

    render(){
        return (
            <div>
                <Menu/>

                <AddBoard createBoard={this.props.createBoard}/>
                <div className="boards">
                    {this.props.boards.map(b => <BoardItem key={b.id} board={b}/>)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    boards: state.boards.boards
});

const mapDispatchToProps = ({
    getBoardList: boardsActions.getBoardList,
    getBoard: boardsActions.getBoard,
    createBoard: boardsActions.createBoard
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardList));
