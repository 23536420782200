import React, {Component} from 'react';

class RemoveTask extends Component{
    constructor(props) {
        super(props);
        this.removeTask = this.removeTask.bind(this);
    }

    removeTask(e){
        this.props.removeTask(this.props.boardId, this.props.taskId);
    }

    render(){
        return (
            <div className="remove-task" onClick={this.removeTask}>
                X
            </div>
        );
    }
}

export default RemoveTask;