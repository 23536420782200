import actionTypes from '../ActionTypes/Tasks';
import Api from '../Api/ApiTasks';
import boardsActions from './Boards';

const actions = {
    createTask: () => {
        return (dispatch, store) => {
            dispatch(actions.loadingCreateTask(true));

            const task = store().tasks.create;

            Api.createTask(store().auth.token, task.boardId, task.title).then(response => {
                dispatch(actions.loadingCreateTask(false));

                if(response.done) {
                    dispatch(boardsActions.addTask(response.id, task.boardId, task.title));
                }

                dispatch(actions.cleanCreateTask());
                dispatch(actions.setCreateParam('boardId', task.boardId));
            });
        };
    },

    loadingCreateTask: (status) => ({
        type: actionTypes.LOADING_CREATE_TASK,
        payload: {
            status
        }
    }),

    setCreateParam: (name, value) => ({
        type: actionTypes.SET_CREATE_PARAM,
        payload: {
            name,
            value
        }
    }),

    cleanCreateTask: () => ({
        type: actionTypes.CLEAN_CREATE_TASK
    }),

    prepareToUpdate: (taskId, boardId, title, status) => ({
        type: actionTypes.PREPARE_UPDATE_TASK,
        payload: {
            taskId,
            boardId,
            title,
            status
        }
    }),

    updateTask: () => {
        return (dispatch, store) => {
            dispatch(actions.loadingUpdateTask(true));

            const task = store().tasks.update;

            Api.updateTask(store().auth.token, task.boardId, task.id, task.title, task.status).then(response => {
                dispatch(actions.loadingUpdateTask(false));

                if(response.done) {
                    dispatch(boardsActions.updateTask(task.id, task.boardId, task.title, task.status));
                }

                dispatch(actions.cleanUpdateTask());
            });
        };
    },

    setUpdateParam: (name, value) => ({
        type: actionTypes.SET_UPDATE_PARAM,
        payload: {
            name,
            value
        }
    }),

    updateStatusTask: (taskId, boardId, title, status) => {
        return (dispatch, store) => {
            Api.updateTask(store().auth.token, boardId, taskId, title, status).then(response => {
                if(response.done) {
                    dispatch(boardsActions.updateTask(taskId, boardId, title, status));
                }
            });
        };
    },

    loadingUpdateTask: (status) => ({
        type: actionTypes.LOADING_UPDATE_TASK,
        payload: {
            status
        }
    }),

    cleanUpdateTask: () => ({
        type: actionTypes.CLEAN_UPDATE_TASK
    }),

    removeTask: (boardId, taskId) => {
        return (dispatch, store) => {
            Api.removeTask(store().auth.token, boardId, taskId).then(response => {
                if(response.done) {
                    dispatch(boardsActions.removeTask(taskId));
                }
            });
        };
    }
};

export default actions;