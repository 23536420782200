import {checkStatus, parseJSON} from "./Utils";

const ApiTasks = {
    createTask: (token, boardId, title) => {
        return fetch('/api/board/' + boardId + '/task', {
            method: 'post',
            headers: {
                token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title
            })
        }).then(checkStatus)
            .then(parseJSON);
    },
    updateTask: (token, boardId, taskId, title, status) => {
        return fetch('/api/board/' + boardId + '/task/' + taskId, {
            method: 'put',
            headers: {
                token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title,
                status
            })
        }).then(checkStatus)
            .then(parseJSON);
    },
    removeTask: (token, boardId, taskId) => {
        return fetch('/api/board/' + boardId + '/task/' + taskId, {
            method: 'delete',
            headers: {
                token,
                'Content-Type': 'application/json'
            }
        }).then(checkStatus)
            .then(parseJSON);
    }
};

export default ApiTasks;