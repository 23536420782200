import api from '../Api/ApiAuth';
import actionTypes from '../ActionTypes/Auth';
import cookie from "react-cookies";

const actions = {
    login: () => {
        return (dispatch, store) => {
            const loginForm = store().auth.loginForm;
            api.login(loginForm.username, loginForm.password).then(response => {

                dispatch(actions.addToken(response.token, response.exp));
            },reason => {
                if(reason.response.status === 401) {
                    reason.response.json().then(response => {
                        dispatch(actions.setLoginParam('login_error', true));
                    });
                }
            });
        }
    },

    setLoginParam: (key, value) => {
        return {
            type: actionTypes.ADD_LOGIN_PARAM,
            payload: {
                key,
                value
            }
        };
    },

    cleanLoginParams: () => {
        return {
            type: actionTypes.CLEAN_LOGIN_PARAMS
        };
    },

    addToken: (token, exp) => ({
        type: actionTypes.ADD_TOKEN,
        payload: {
            token,
            exp
        }
    }),

    exitApp: () => {
        return (dispatch, store) => {
            dispatch(actions.addToken('', 0));
            cookie.remove('tasks', {path: '/'});
        }
    },

    readCookie: () => {
        return (dispatch, store) => {
            try {
                let lhCookie = JSON.parse(cookie.load('tasks', {path: '/'}));
                if (lhCookie && lhCookie.token) {
                    dispatch(actions.addToken(lhCookie.token, lhCookie.exp));
                }else{
                    dispatch(actions.addToken('', 0));
                }
            } catch (e) {
                dispatch(actions.addToken('', 0));
            }
        }
    },

    register: () => {
        return (dispatch, store) => {
            const registerForm = store().auth.registerForm;
            api.register(registerForm.username, registerForm.email, registerForm.password).then(response => {

                dispatch(actions.addToken(response.token, response.exp));
            },reason => {
                if(reason.response.status === 400) {
                    reason.response.json().then(response => {
                        dispatch(actions.setRegisterParam('register_error', true));
                    });
                }
            });
        }
    },

    setRegisterParam: (key, value) => {
        return {
            type: actionTypes.ADD_REGISTER_PARAM,
            payload: {
                key,
                value
            }
        };
    },

    cleanRegisterParams: () => {
        return {
            type: actionTypes.CLEAN_REGISTER_PARAMS
        };
    },
};

export default actions;