import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import boards from './boards';
import tasks from './tasks';

export default (history) => combineReducers({
    router: connectRouter(history),
    auth,
    boards,
    tasks
});