import actionTypes from '../ActionTypes/Auth';
import update from 'immutability-helper';
import cookie from 'react-cookies';

const initialState = {
    token: '',
    exp: 0,
    loginForm: {
        username: '',
        password: '',
        login_error: false
    },
    registerForm: {
        username: '',
        email: '',
        password: '',
        register_error: false
    }
};

const auth = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.ADD_TOKEN:
            cookie.save('tasks', JSON.stringify({
                token: action.payload.token,
                exp: action.payload.exp
            }),{
                path: '/'
            });
            return update(state, {
                token: {$set: action.payload.token},
                exp: {$set: action.payload.exp}
            });
        case actionTypes.ADD_LOGIN_PARAM:
            return update(state, {loginForm: {[action.payload.key]: {$set: action.payload.value}}});
        case actionTypes.CLEAN_LOGIN_PARAMS:
            return update(state, {loginForm:
                    {$set:
                            {
                                username: '',
                                password: '',
                                login_error: false
                            }
                    }
            });
        case actionTypes.ADD_REGISTER_PARAM:
            return update(state, {registerForm: {[action.payload.key]: {$set: action.payload.value}}});
        case actionTypes.CLEAN_REGISTER_PARAMS:
            return update(state, {registerForm:
                    {$set:
                            {
                                username: '',
                                email: '',
                                password: '',
                                register_error: false
                            }
                    }
            });
        default:
            return state;
    }
};

export default auth;