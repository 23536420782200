import React, {Component} from 'react';
import {Link} from "react-router-dom";

class BoardItem extends Component{
    render(){
        return (
            <div className="board"><Link to={'/board/' + this.props.board.id}>{this.props.board.name}</Link></div>
        )
    }
}

export default BoardItem;