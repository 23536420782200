import actionTypes from '../ActionTypes/Boards';
import Api from '../Api/ApiBoards';

const actions = {
    addTask: (taskId, boardId, title) => ({
        type: actionTypes.ADD_TASK,
        payload: {
            taskId,
            boardId,
            title
        }
    }),

    updateTask: (taskId, boardId, title, status) => ({
        type: actionTypes.UPDATE_TASK,
        payload: {
            taskId,
            boardId,
            title,
            status
        }
    }),

    removeTask: (taskId) => ({
        type: actionTypes.REMOVE_TASK,
        payload: {
            taskId
        }
    }),

    getBoardList: () => {
        return (dispatch, store) => {
            dispatch(actions.loadingBoards(true));

            Api.getBoardList(store().auth.token).then(response => {
                dispatch(actions.loadingBoards(false));

                dispatch({
                    type: actionTypes.SET_BOARD_LIST,
                    payload: {
                        boards: response.boards
                    }
                });
            });
        };
    },

    loadingBoards: (status) => ({
        type: actionTypes.LOADING_BOARDS,
        payload: {
            status
        }
    }),

    getBoard: (boardId) => {
        return (dispatch, store) => {
            dispatch(actions.setSelectedBoard(boardId));
            dispatch(actions.loadingBoard(true));

            Api.getBoard(store().auth.token, boardId).then(response => {
                dispatch(actions.loadingBoard(false));

                dispatch({
                    type: actionTypes.SET_BOARD_TASKS,
                    payload: {
                        tasks: response.tasks
                    }
                });
            });
        };
    },

    setSelectedBoard: (boardId) => ({
        type: actionTypes.SET_SELECTED_BOARD,
        payload: {
            boardId
        }
    }),

    loadingBoard: (status) => ({
        type: actionTypes.LOADING_BOARD,
        payload: {
            status
        }
    }),

    createBoard: (name) => {
        return (dispatch, store) => {
            dispatch(actions.loadingCreateBoard(true));

            Api.createBoard(store().auth.token, name).then(response => {
                dispatch(actions.loadingCreateBoard(false));

                if(response.done) {
                    dispatch({
                        type: actionTypes.ADD_BOARD,
                        payload: {
                            boardId: response.id,
                            name
                        }
                    });
                }
            });
        };
    },

    loadingCreateBoard: (status) => ({
        type: actionTypes.LOADING_CREATE_BOARD,
        payload: {
            status
        }
    }),

    updateBoard: (boardId, name) => {
        return (dispatch, store) => {
            dispatch(actions.loadingUpdateBoard(true));

            Api.updateBoard(store().auth.token, boardId, name).then(response => {
                dispatch(actions.loadingUpdateBoard(false));

                if(response.done) {
                    dispatch({
                        type: actionTypes.UPDATE_BOARD,
                        payload: {
                            boardId,
                            name
                        }
                    });
                }
            });
        };
    },

    loadingUpdateBoard: (status) => ({
        type: actionTypes.LOADING_UPDATE_BOARD,
        payload: {
            status
        }
    }),

    removeBoard: (boardId) => {
        return (dispatch, store) => {
            Api.removeBoard(store().auth.token, boardId).then(response => {
                if(response.done) {
                    dispatch({
                        type: actionTypes.REMOVE_BOARD,
                        payload: {
                            boardId
                        }
                    });
                }
            });
        };
    }
};

export default actions;